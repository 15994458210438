<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Support Tickets</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <button type="button" @click="createTicketModalOpen()" class="header-btn-outer btn btn-primary">
                Create New Ticket
            </button>
            <button type="button" @click="ticketFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="new-ticket-tab" data-bs-toggle="tab" data-bs-target="#newticketdetail"
                type="button" role="tab" aria-controls="vehicle-details" aria-selected="false"
                @click="redirectNewTicketUrl()">
                New Tickets
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="open-ticket-tab" data-bs-toggle="tab" data-bs-target="#openticketdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectOpenTicketUrl()">
                Open Tickets
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="closed-ticket-tab" data-bs-toggle="tab" data-bs-target="#closedticketdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="true">
                Closed Tickets
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="cancelled-ticket-tab" data-bs-toggle="tab" data-bs-target="#cancelledticketdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectCancelledTicketUrl()">
                Cancelled Tickets
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade" id="newticketdetail" role="tabpanel" aria-labelledby="new-ticket-tab">
            New Tickets
        </div>
        <div class="tab-pane fade" id="openticketdetail" role="tabpanel" aria-labelledby="open-ticket-tab">
            Open Tickets
        </div>
        <div class="tab-pane fade show active" id="closedticketdetail" role="tabpanel" aria-labelledby="closed-ticket-tab">
            <div class="company-section-outer">
                <div class="layout-content-section">
                    <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
                        <DataTable :value="newTicketList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
                            :paginator="true" :rows="30" :lazy="true" :rowHover="true" dataKey="sa1"
                            :totalRecords="totalRecords" @page="changePage($event)"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            responsiveLayout="scroll"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :loading="loading">
                            <template v-if="!loading" #empty>No records found.</template>
                            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                            <Column field="datatime" header="Submitted Date" headerStyle="width: 14%"
                                bodyStyle="width: 14%">
                                <template #body="{ data }">
                                    <div>
                                        {{ format_timestamp(data.sa12) }}
                                    </div>
                                </template>
                            </Column>
                            <Column field="ticketnumber" header="Ticket Number" headerStyle="width: 8%"
                                bodyStyle="width: 8%">
                                <template #body="{ data }">
                                    <div>
                                        {{ data.sa4 ? data.sa4 : "N/A" }}
                                    </div>
                                </template>
                            </Column>
                            <Column field="createdby" header="Created By" headerStyle="width: 15%" bodyStyle="width: 15%">
                                <template #body="{ data }">
                                    <div class="text-capitalize">
                                        {{ data.sa31 ? data.sa31 : "N/A" }}
                                    </div>
                                </template>
                            </Column>
                            <Column field="thirdparty" header="Third Party Dependency" headerStyle="width: 10%"
                                bodyStyle="width: 10%">
                                <template #body="{ data }">
                                    <div>
                                        <span v-if="data.sa26 == 1">Yes</span>
                                        <span v-if="data.sa26 == 2">No</span>
                                        <span v-if="data.sa26 == '' || data.sa26 == null">N/A</span>
                                    </div>
                                </template>
                            </Column>
                            <Column field="tentativeclosing" header="Tentative Closing" headerStyle="width: 8%"
                                bodyStyle="width: 8%">
                                <template #body="{ data }">
                                    <div>
                                        {{ data.sa25 ? data.sa25 : "N/A" }}
                                    </div>
                                </template>
                            </Column>
                            <Column field="alocatedto" header="Allocated To" headerStyle="width: 14%"
                                bodyStyle="width: 14%">
                                <template #body="{ data }">
                                    <div class="text-capitalize">
                                        {{ data.sa17 ? data.sa17 : "N/A" }}
                                    </div>
                                </template>
                            </Column>
                            <Column field="closedby" header="Closed By / Date" headerStyle="width: 19%"
                                bodyStyle="width: 19%">
                                <template #body="{ data }">
                                    <div class="w-100">
                                        <div class="text-capitalize mb-1" title="Closed By">
                                            {{ data.sa34 ? data.sa34 : "N/A" }}
                                        </div>
                                        <Divider />
                                        <div title="Closed Date">
                                            {{ format_timestamp(data.sa32) }}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="action" header="Action" headerStyle="width: 7%" bodyStyle="width: 7%" class="justify-content-end">
                                <template #body="{ data }">
                                    <button type="button" title="Ticket History" class="btn custom-outline-view-btn me-2"
                                        @click="newTicketHistoyModalOpen(data)">
                                        <i class="pi pi-history call-btn-color"></i>
                                    </button>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="cancelledticketdetail" role="tabpanel" aria-labelledby="cancelled-ticket-tab">
            Cancelled Tickets
        </div>
    </div>
    <!-- Ticket History Modal Start Here -->
    <div class="modal-mask" v-if="newtickethistorymodalstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Ticket History</h5>
                    <button type="button" class="btn-close" @click="newTicketHistoyModalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer" style="height:488px;">
                    <div v-if="!showloaderhistorymodal">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="ticket-history-box-outer mb-3">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Ticket No.</label>
                                                <div class="from-label-value">
                                                    {{ this.getticketHistory.sa1 ? this.getticketHistory.sa1 : 'N/A' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Request For</label>
                                                <div class="from-label-value">
                                                    <span v-if="this.getticketHistory.sa6 == 1">Facing Technical
                                                        Problem</span>
                                                    <span v-if="this.getticketHistory.sa6 == 2">Need Help In Some
                                                        Settings</span>
                                                    <span
                                                        v-if="this.getticketHistory.sa6 == '' || this.getticketHistory.sa6 == null">N/A</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Request Date</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_timestamp(this.getticketHistory.sa12) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Ticket Status</label>
                                                <div class="from-label-value text-capitalize">
                                                    <span v-if="this.getticketHistory.sa18 == 1">New</span>
                                                    <span v-if="this.getticketHistory.sa18 == 2">Open</span>
                                                    <span v-if="this.getticketHistory.sa18 == 3">Closed</span>
                                                    <span v-if="this.getticketHistory.sa18 == 4">Cancelled</span>
                                                    <span v-if="this.getticketHistory.sa18 == 5">Cancelled By Client</span>
                                                    <span
                                                        v-if="this.getticketHistory.sa18 == '' || this.getticketHistory.sa18 == null">N/A</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Allocated To</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ this.getticketHistory.sa17 ? getticketHistory.sa17 : 'N/A' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ticket-chat-box-outer">
                            <div class="row">
                                <div class="col-12">
                                    <div class="history-conversation-inner">
                                        <div v-for="msg in chatHistoryList" :key="msg">
                                            <div class="d-flex align-items-start mb-2" v-if="msg.sb12 == 2">
                                                <div class="flex-shrink-0">
                                                    <img v-if="msg.profileimageurl == null || msg.profileimageurl == ''"
                                                        src="/assets/images/profile.svg" class="me-2 rounded-circle" alt=""
                                                        width="32" height="32" />
                                                    <img v-if="msg.profileimageurl != null && msg.profileimageurl != ''"
                                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/User/Logo/' + msg.profileimageurl"
                                                        class="me-2 rounded-circle" alt="" width="32" height="32" />
                                                </div>
                                                <div class="flex-grow-1 d-flex flex-column ms-2">
                                                    <div class="history-avatar-name text-capitalize">{{ msg.sb11 }}</div>
                                                    <div class="message-history msg-history-received">
                                                        <div>{{ msg.sb5 }}</div>
                                                        <span class="metadata"><span class="time">{{
                                                            format_timestamp(msg.z501) }}</span></span>
                                                    </div>
                                                    <div v-if="msg.sb6 != '' && msg.sb6 != null">
                                                        <span class="me-2"><i class="pi pi-paperclip me-1"></i>Attachment's
                                                            : </span>
                                                        <span v-for="(value, key) in splitJoin(msg.sb6)" :key="key"
                                                            class="image-item">
                                                            <button class="btn btn-link attchement-received-image"
                                                                @click="previewImageModalOpen(value)"><img :src="value"
                                                                    class="rounded" alt="" width="32" height="32" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-start mb-2" v-if="msg.sb12 == 1">
                                                <div class="flex-grow-1 d-flex align-items-end flex-column me-2">
                                                    <div class="history-avatar-name text-capitalize">{{ msg.sb11 }}</div>
                                                    <div class="message-history msg-history-sent">
                                                        <div>{{ msg.sb5 }}</div>
                                                        <span class="metadata"><span class="time">{{
                                                            format_timestamp(msg.z501) }}</span></span>
                                                    </div>
                                                    <div v-if="msg.sb6 != '' && msg.sb6 != null">
                                                        <span class="me-2"><i class="pi pi-paperclip me-1"></i>Attachment's
                                                            : </span>
                                                        <span v-for="(value, key) in splitJoin(msg.sb6)" :key="key"
                                                            class="image-item">
                                                            <button class="btn btn-link attchement-received-image"
                                                                @click="previewImageModalOpen(value)"><img :src="value"
                                                                    class="rounded" alt="" width="32" height="32" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <img v-if="(msg.profileimageurl.addharImage == null || msg.profileimageurl.addharImage == '') && (msg.profileimageurl.profileImage == null || msg.profileimageurl.profileImage == '')"
                                                        src="/assets/images/profile.svg" class="profile-header-avatar rounded-circle ms-2" alt=""
                                                        width="32" height="32" />
                                                        <img v-if="msg.profileimageurl.profileImage != null && msg.profileimageurl.profileImage != ''"
                                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + msg.profileimageurl.profileImage"
                                                        class="profile-header-avatar rounded-circle ms-2" alt="" width="32" height="32" />
                                                        <img v-if="(msg.profileimageurl.addharImage != null && msg.profileimageurl.addharImage != '') && (msg.profileimageurl.profileImage == null || msg.profileimageurl.profileImage == '')"
                                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + msg.profileimageurl.addharImage"
                                                        class="profile-header-avatar rounded-circle ms-2" alt="" width="32" height="32" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showloaderhistorymodal">
                        <div class="custom-modal-spinner-loader">
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Ticket History Modal End Here -->
    <!-- filter start here -->
    <div class="modal-mask" v-if="ticketfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="ticketFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Problem In</label>
                                <Multiselect v-model="sa5" :options="problemList" :searchable="false" label="label"
                                    placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                    :object="true" noOptionsText="No Result" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn" @click="
                                btnFilterSearch(sa5)
                                " :disabled="sa5 == ''">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" :disabled="sa5 == ''" @click="resetVoterFilter()">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
    <!-- Create Tickets Modal start here -->
    <div class="modal-mask" v-if="createticketmodalstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Create Ticket</h5>
                    <button type="button" class="btn-close" @click="createTicketModalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select what type of support you need<span
                                        class="text-danger">*</span></label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="create.tickettype" name="techical-option"
                                        id="technical-type" autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="technical-type">Facing
                                        technical problem</label>
                                    <input type="radio" class="btn-check" v-model="create.tickettype" name="logical-option"
                                        id="logical-type" autocomplete="off" value="2" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="logical-type">Need help in
                                        some
                                        settings (Navigational guidance)</label>
                                </div>
                                <div class="custom-error" v-if="v$.create.tickettype.$error">
                                    {{ v$.create.tickettype.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="create.tickettype == 1">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select what type of support you need<span
                                        class="text-danger">*</span></label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="create.problemin" name="web-option"
                                        id="web-application" autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="web-application">Web
                                        Application</label>
                                    <input type="radio" class="btn-check" v-model="create.problemin" name="android-option"
                                        id="android-application" autocomplete="off" value="2" />
                                    <label class="btn custom-radio-box-btn text-capitalize"
                                        for="android-application">Android
                                        Application</label>
                                    <input type="radio" class="btn-check" v-model="create.problemin" name="ios-option"
                                        id="ios-application" autocomplete="off" value="3" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="ios-application">IOS
                                        Application</label>
                                    <input type="radio" class="btn-check" v-model="create.problemin" name="all-option"
                                        id="all-application" autocomplete="off" value="4" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="all-application">All
                                        Application</label>
                                </div>
                                <div class="custom-error" v-if="v$.create.problemin.$error">
                                    {{ v$.create.problemin.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="create.tickettype == 1">
                        <div class="col-lg-12 col-md-12 col-12" v-if="create.problemin == 1">
                            <div class="custom-form-group">
                                <label for="formEffectedInput" class="form-label">Effected URL<span
                                        class="text-danger">*</span></label>
                                <input type="text" @keypress="spaceNotAllowed($event)" v-model="create.effectedurl"
                                    class="form-control" placeholder="Enter URL" autocomplete="off" />
                                <div class="custom-error" v-if="v$.create.effectedurl.$error">
                                    {{ v$.create.effectedurl.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12"
                            v-if="create.problemin == 1 || create.problemin == 2 || create.problemin == 3 || create.problemin == 4">
                            <div class="custom-form-group">
                                <label for="formLEffectedInput"
                                    class="form-label d-flex justify-content-between"><span>Effected User<span
                                            class="text-danger">*</span></span><small class="muted-text text-primary">(Max
                                        3)</small></label>
                                <Multiselect v-model="create.effecteduser" :options="userList" mode="tags"
                                    :createTag="false" label="label"
                                    class="multiselect-custom form-custom-select text-capitalize" placeholder="Select"
                                    :closeOnSelect="true" :searchable="true" :object="true" :max="3" />
                                <div class="custom-error" v-if="v$.create.effecteduser.$error">
                                    {{ v$.create.effecteduser.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12"
                            v-if="create.problemin == 1 || create.problemin == 2 || create.problemin == 3 || create.problemin == 4">
                            <div class="custom-form-group">
                                <label for="formModuleInput"
                                    class="form-label d-flex justify-content-between"><span>Select
                                        Module</span><small class="muted-text text-primary">(Max
                                        3)</small></label>
                                <Multiselect v-model="create.effectedmodule" :options="moduleList" mode="tags"
                                    :createTag="false" label="label"
                                    class="multiselect-custom form-custom-select text-capitalize" placeholder="Select"
                                    :closeOnSelect="true" :searchable="true" :object="true" :max="3" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12"
                            v-if="create.problemin == 1 || create.problemin == 2 || create.problemin == 3 || create.problemin == 4">
                            <div class="custom-form-group">
                                <label for="formShortNameInput" class="form-label">Problem Description<span
                                        class="text-danger">*</span></label>
                                <textarea type="text" v-model="create.problemdesc"
                                    class="form-control custom-text-area-scroll" placeholder="Enter Problem Description"
                                    autocomplete="off" rows="3" maxlength="250"
                                    v-on:keyup="displayCreateTechDescCDown"></textarea>
                                <div class="text-muted text-end" v-if="create.problemdesc.length != 0">{{
                                    TechCreateRemainCountTotal
                                }}/250</div>
                                <div class="custom-error" v-if="v$.create.problemdesc.$error">
                                    {{ v$.create.problemdesc.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="create.problemin != ''">
                            <div class="row">
                                <div class="col-lg-7 col-md-7 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Screenshots <small
                                                class="muted-text text-primary">(Max
                                                5)</small></label>
                                        <FileUpload mode="basic" ref="ticketscreenref" class="custom-upload-btn-ultima"
                                            v-model="create.uploadscreenimg" aria-describedby="upload-limit"
                                            :multiple="true" accept="image/*" :auto="true" :fileLimit="5"
                                            v-on:change="handleCreateTicketScreenAdd()" />
                                        <div class="custom-error" v-if="errorscreenimgupload">{{ errorscreenimgupload }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center"
                                    v-if="uploadscreencount != ''">
                                    <div class="ticket-upload-file-count-label">
                                        <i class="pi pi-images" style="font-size: 0.8rem"></i> You have <span>{{
                                            this.uploadscreencount }}</span>
                                        file<span v-if="this.uploadscreencount >= 2">s</span> selected.
                                    </div>
                                    <button type="button" title="Clear Attched Files"
                                        class="btn custom-outline-delete-btn ms-2" @click="clearticketscreenimages()">
                                        <i class="pi pi-trash delete-btn-color"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-12 col-md-12 col-12 d-flex">
                                    <button class="btn btn-link p-0 me-3"
                                        v-if="createpreviewurl1 != null && createpreviewurl1 != ''">
                                        <img :src="createpreviewurl1.objectURL"
                                            @click="previewImageModalOpen(createpreviewurl1.objectURL)" class="
                                    rounded
                                    bg-light
                                  " :alt="createpreviewurl1.name" width="145" />
                                    </button>
                                    <button class="btn btn-link p-0 me-3"
                                        v-if="createpreviewurl2 != null && createpreviewurl2 != ''">
                                        <img :src="createpreviewurl2.objectURL"
                                            @click="previewImageModalOpen(createpreviewurl2.objectURL)" class="
                                    rounded
                                    bg-light
                                  " :alt="createpreviewurl2.name" width="145" />
                                    </button>
                                    <button class="btn btn-link p-0 me-3"
                                        v-if="createpreviewurl3 != null && createpreviewurl3 != ''">
                                        <img :src="createpreviewurl3.objectURL"
                                            @click="previewImageModalOpen(createpreviewurl3.objectURL)" class="
                                    rounded
                                    bg-light
                                  " :alt="createpreviewurl3.name" width="145" />
                                    </button>
                                    <button class="btn btn-link p-0 me-3"
                                        v-if="createpreviewurl4 != null && createpreviewurl4 != ''">
                                        <img :src="createpreviewurl4.objectURL"
                                            @click="previewImageModalOpen(createpreviewurl4.objectURL)" class="
                                    rounded
                                    bg-light
                                  " :alt="createpreviewurl4.name" width="145" />
                                    </button>
                                    <button class="btn btn-link p-0 me-3"
                                        v-if="createpreviewurl5 != null && createpreviewurl5 != ''">
                                        <img :src="createpreviewurl5.objectURL"
                                            @click="previewImageModalOpen(createpreviewurl5.objectURL)" class="
                                    rounded
                                    bg-light
                                  " :alt="createpreviewurl5.name" width="145" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="create.tickettype == 2">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label for="formShortNameInput" class="form-label">Kindly let us know in detail what
                                    settings
                                    you want to understand<span class="text-danger">*</span></label>
                                <textarea type="text" v-model="create.logicaldesc"
                                    class="form-control custom-text-area-scroll" placeholder="Enter Description"
                                    autocomplete="off" rows="3" maxlength="250"
                                    v-on:keyup="displayCreateLogiDescCDown"></textarea>
                                <div class="text-muted text-end" v-if="create.logicaldesc.length != 0">{{
                                    LogiCreateRemainCountTotal
                                }}/250</div>
                                <div class="custom-error" v-if="v$.create.logicaldesc.$error">
                                    {{ v$.create.logicaldesc.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="createsupportticket()"
                            :disabled="showcreatebtnloader">
                            <span v-if="!showcreatebtnloader">Create</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showcreatebtnloader"></div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Create Tickets Modal end here -->
    <!-- Preview screen images start here -->
    <div v-if="imagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Screen Preview</h4>
                    <button class="btn-close" @click="previewImageModalClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img :src="imgsourcepath" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
    <!-- Preview screen images end here -->
</template>
<script>
import { required, helpers, requiredIf } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            newTicketList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            newtickethistorymodalstatus: false,
            newticketcancelmodalstatus: false,
            showhbtnloader: false,
            showcbtnloader: false,
            showloaderhistorymodal: false,
            sa5: '',
            problemList: [
                { label: "Web Application", value: 1 },
                { label: "Android Application", value: 2 },
                { label: "IOS Application", value: 3 },
                { label: "All Application", value: 4 },
            ],
            ticketfiltermodalsflag: false,
            createticketmodalstatus: false,
            create: {
                tickettype: '',
                problemin: '',
                logicaldesc: '',
                effectedurl: '',
                effecteduser: [],
                effectedmodule: [],
                problemdesc: '',
                uploadscreenimg: [],
            },
            userList: [],
            moduleList: [],
            createpreviewurl1: null,
            createpreviewurl2: null,
            createpreviewurl3: null,
            createpreviewurl4: null,
            createpreviewurl5: null,
            errorscreenimgupload: '',
            showcreatebtnloader: false,
            TechRemainCountTotal: 250,
            logiMxLimitCount: 250,
            techCreMxLimitCount: 250,
            logiCreMxLimitCount: 250,
            TechCreateRemainCountTotal: 250,
            LogiCreateRemainCountTotal: 250,
            screenfile1: '',
            screenfile2: '',
            screenfile3: '',
            screenfile4: '',
            screenfile5: '',
            uploadscreencount: '',
            tickethistoryfk: '',
            chatHistoryList: [],
            getticketHistory: [],
            imagePreviewDialog: false,
            imgsourcepath: '',
            client_info: '',
            voterstorageimgpath: '',
        };
    },
    validations() {
        return {
            create: {
                tickettype: { required: helpers.withMessage('Please select ticket type', required) },
                problemin: {
                    requiredIf: helpers.withMessage(
                        "Please select problem in",
                        requiredIf(
                            this.create.tickettype == 1
                        )
                    ),
                },
                logicaldesc: {
                    requiredIf: helpers.withMessage(
                        "Please enter description",
                        requiredIf(
                            this.create.tickettype == 2
                        )
                    ),
                },
                effectedurl: {
                    requiredIf: helpers.withMessage(
                        "Please select URL",
                        requiredIf(
                            this.create.problemin == 1
                        )
                    ),
                },
                effecteduser: {
                    requiredIf: helpers.withMessage(
                        "Please select user",
                        requiredIf(
                            this.create.problemin == 1 || this.create.problemin == 2 || this.create.problemin == 3 || this.create.problemin == 4
                        )
                    ),
                },
                problemdesc: {
                    requiredIf: helpers.withMessage(
                        "Please select description",
                        requiredIf(
                            this.create.problemin == 1 || this.create.problemin == 2 || this.create.problemin == 3 || this.create.problemin == 4
                        )
                    ),
                },
            }
        };
    },
    mounted() {
        this.getticketsbystatus({ page_no: this.page_no, sa5: this.sa5, status: 3, });
        this.getactiveusers();
        this.getModules();
        this.client_info = JSON.parse(localStorage.client_info);
        this.voterstorageimgpath = this.client_info.maa24;
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        splitJoin(theText) {
            return theText.split(',');
        },
        createTicketModalOpen() {
            this.createticketmodalstatus = true;
            this.create.tickettype = '';
            this.create.problemin = '';
            this.create.logicaldesc = '';
            this.create.effectedurl = '';
            this.create.effecteduser = [];
            this.create.effectedmodule = [];
            this.create.problemdesc = '';
            this.create.uploadscreenimg = '';
            this.screenfile1 = '';
            this.screenfile2 = '';
            this.screenfile3 = '';
            this.screenfile4 = '';
            this.screenfile5 = '';
            this.createpreviewurl1 = null;
            this.createpreviewurl2 = null;
            this.createpreviewurl3 = null;
            this.createpreviewurl4 = null;
            this.createpreviewurl5 = null;
            this.uploadscreencount = '';
            this.errorscreenimgupload = '';
        },
        createTicketModalClose() {
            this.createticketmodalstatus = false;
        },
        redirectNewTicketUrl() {
            this.$router.push("/support/new");
        },
        redirectOpenTicketUrl() {
            this.$router.push("/support/open");
        },
        redirectCancelledTicketUrl() {
            this.$router.push("/support/cancelled");
        },
        displayCreateTechDescCDown: function () {
            this.TechCreateRemainCountTotal = this.techCreMxLimitCount - this.create.problemdesc.length;
        },
        displayCreateLogiDescCDown: function () {
            this.LogiCreateRemainCountTotal = this.logiCreMxLimitCount - this.create.logicaldesc.length;
        },
        newTicketHistoyModalOpen(history) {
            this.newtickethistorymodalstatus = true;
            this.showloaderhistorymodal = true;
            this.getticketmessages({ sa1: history.sa1 });
            this.tickethistoryfk = history.sa1;
        },
        newTicketHistoyModalClose() {
            this.newtickethistorymodalstatus = false;
        },
        ticketFilterModalOpen() {
            this.ticketfiltermodalsflag = true;
        },
        ticketFilterModalClose() {
            this.ticketfiltermodalsflag = false;
        },
        btnFilterSearch(sa5
        ) {
            this.sa5 = sa5;
            this.ticketfiltermodalsflag = false;
            this.getticketsbystatus({
                page_no: this.page_no,
                sa5: this.sa5,
                status: 3
            });
        },
        resetVoterFilter() {
            this.sa5 = '';
            this.getticketsbystatus({
                page_no: this.page_no,
                status: 3
            });
            this.ticketfiltermodalsflag = false;
        },
        reloadnewticket() {
            this.loading = true;
            this.ApiService.getticketsbystatus({ status: 3 }).then((items) => {
                if (items.status == 200) {
                    this.newTicketList = items.data;
                    this.totalRecords = items.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.newTicketList = null;
                    this.totalRecords = 0;
                }
            });
        },
        getticketsbystatus(e) {
            this.loading = true;
            this.ApiService.getticketsbystatus(e).then((items) => {
                if (items.status == 200) {
                    this.newTicketList = items.data;
                    this.totalRecords = items.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.newTicketList = null;
                    this.totalRecords = 0;
                }
            });
        },
        getactiveusers(e) {
            this.ApiService.getactiveusers(e).then((data) => {
                if (data.status == 200) {
                    this.userList = data.data;
                } else {
                    this.userList = [];
                }
            });
        },
        getModules(e) {
            this.ApiService.getModules(e).then((data) => {
                if (data.status == 200) {
                    this.moduleList = data.parentModules;
                } else {
                    this.moduleList = [];
                }
            });
        },
        // new ticket create upload
        handleCreateTicketScreenAdd() {
            var file = this.$refs.ticketscreenref.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.errorscreenimgupload = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorscreenimgupload = 'Invalid file type';
            } else if (this.$refs.ticketscreenref.files.length > 5) {
                this.errorscreenimgupload = 'Maximum 5 files are allowed';
                this.uploadscreencount = this.$refs.ticketscreenref.files.length;
            } else {
                this.errorscreenimgupload = '';
                this.screenfile1 = null;
                this.screenfile2 = null;
                this.screenfile3 = null;
                this.screenfile4 = null;
                this.screenfile5 = null;
                this.createpreviewurl1 = null;
                this.createpreviewurl2 = null;
                this.createpreviewurl3 = null;
                this.createpreviewurl4 = null;
                this.createpreviewurl5 = null;
                let len = this.$refs.ticketscreenref.files.length;
                if (len === 1) {
                    this.screenfile1 = this.$refs.ticketscreenref.files[0];
                } else if (len === 2) {
                    this.screenfile1 = this.$refs.ticketscreenref.files[0];
                    this.screenfile2 = this.$refs.ticketscreenref.files[1];
                } else if (len === 3) {
                    this.screenfile1 = this.$refs.ticketscreenref.files[0];
                    this.screenfile2 = this.$refs.ticketscreenref.files[1];
                    this.screenfile3 = this.$refs.ticketscreenref.files[2];
                } else if (len === 4) {
                    this.screenfile1 = this.$refs.ticketscreenref.files[0];
                    this.screenfile2 = this.$refs.ticketscreenref.files[1];
                    this.screenfile3 = this.$refs.ticketscreenref.files[2];
                    this.screenfile4 = this.$refs.ticketscreenref.files[3];
                } else if (len === 5) {
                    this.screenfile1 = this.$refs.ticketscreenref.files[0];
                    this.screenfile2 = this.$refs.ticketscreenref.files[1];
                    this.screenfile3 = this.$refs.ticketscreenref.files[2];
                    this.screenfile4 = this.$refs.ticketscreenref.files[3];
                    this.screenfile5 = this.$refs.ticketscreenref.files[4];
                }
                this.uploadscreencount = len;
                if (this.screenfile1 != null) {
                    this.createpreviewurl1 = this.screenfile1;
                }
                if (this.screenfile2 != null) {
                    this.createpreviewurl2 = this.screenfile2;
                }
                if (this.screenfile3 != null) {
                    this.createpreviewurl3 = this.screenfile3;
                }
                if (this.screenfile4 != null) {
                    this.createpreviewurl4 = this.screenfile4;
                }
                if (this.screenfile5 != null) {
                    this.createpreviewurl5 = this.screenfile5;
                }
            }
        },
        clearticketscreenimages() {
            this.screenfile1 = '';
            this.screenfile2 = '';
            this.screenfile3 = '';
            this.screenfile4 = '';
            this.screenfile5 = '';
            this.createpreviewurl1 = null;
            this.createpreviewurl2 = null;
            this.createpreviewurl3 = null;
            this.createpreviewurl4 = null;
            this.createpreviewurl5 = null;
            this.uploadscreencount = '';
            this.errorscreenimgupload = '';
        },
        createsupportticket() {
            this.v$.create.$validate();
            var formData = new FormData();
            formData.append('sa6', this.create.tickettype);
            if (this.create.tickettype == 1) {
                formData.append("sa5", this.create.problemin);
            }
            if (this.create.tickettype == 2) {
                formData.append("sb5", this.create.logicaldesc);
            }
            if (this.create.problemin == 1) {
                formData.append("sa22", this.create.effectedurl);
            }
            if (this.create.problemin == 1 || this.create.problemin == 2 || this.create.problemin == 3 || this.create.problemin == 4) {
                formData.append('effecteduser', JSON.stringify(this.create.effecteduser));
                formData.append("sb5", this.create.problemdesc);
                if (this.create.effectedmodule != '') {
                    formData.append("effectedmodule", JSON.stringify(this.create.effectedmodule));
                }
                if (this.screenfile1 != '' && this.screenfile1 != null) {
                    formData.append("screenfile1", this.screenfile1);
                }
                if (this.screenfile2 != '' && this.screenfile2 != null) {
                    formData.append("screenfile2", this.screenfile2);
                }
                if (this.screenfile3 != '' && this.screenfile3 != null) {
                    formData.append("screenfile3", this.screenfile3);
                }
                if (this.screenfile4 != '' && this.screenfile4 != null) {
                    formData.append("screenfile4", this.screenfile4);
                }
                if (this.screenfile5 != '' && this.screenfile5 != null) {
                    formData.append("screenfile5", this.screenfile5);
                }
            }
            if (!this.v$.create.$error && this.errorscreenimgupload == '') {
                this.showcreatebtnloader = true;
                this.ApiService.createticketapi(formData).then((data) => {
                    if (data.success == true) {
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.create.tickettype = '';
                        this.create.problemin = '';
                        this.create.logicaldesc = '';
                        this.create.effectedurl = '';
                        this.create.effecteduser = [];
                        this.create.effectedmodule = [];
                        this.create.problemdesc = '';
                        this.create.uploadscreenimg = '';
                        this.screenfile1 = '';
                        this.screenfile2 = '';
                        this.screenfile3 = '';
                        this.screenfile4 = '';
                        this.screenfile5 = '';
                        this.createpreviewurl1 = null;
                        this.createpreviewurl2 = null;
                        this.createpreviewurl3 = null;
                        this.createpreviewurl4 = null;
                        this.createpreviewurl5 = null;
                        this.uploadscreencount = '';
                        this.errorscreenimgupload = '';
                        this.showcreatebtnloader = false;
                        this.createticketmodalstatus = false;
                        this.reloadnewticket();
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.showcreatebtnloader = false;
                    }
                });
            }
        },
        changePage(event) {
            this.page_no = event.page;
            this.getticketsbystatus({ page_no: this.page_no, status: 3 });
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        spaceNotAllowed(e) {
            var key = e.keyCode;
            if (key == 32) {
                e.preventDefault();
                return false;
            }
        },
        getticketmessages(e) {
            this.ApiService.getticketmessages(e).then((data) => {
                if (data.status == 200) {
                    this.getticketHistory = data.data;
                    this.chatHistoryList = data.data.ticket_messages;
                    this.showloaderhistorymodal = false;
                } else {
                    this.getticketHistory = [];
                    this.chatHistoryList = [];
                    this.showloaderhistorymodal = false;
                }
            });
        },
        previewImageModalClose() {
            this.imagePreviewDialog = false;
        },
        previewImageModalOpen(e) {
            this.imagePreviewDialog = true;
            this.imgsourcepath = e;
        },
    },
};
</script>
<style scoped>
.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    width: 115px;
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}</style>